import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faBook,
  faCalendarAlt,
  faChevronDown,
  faChevronUp,
  faFileAlt,
  faListUl,
  faNewspaper,
  faSitemap,
  faThLarge,
  faTimes,
  faUserFriends, faBullseye
} from "@fortawesome/free-solid-svg-icons";

import useDeviceDetect from "../hooks/useDeviceDetect";

import logoNegative from "../assets/logo-apps/logo disdik special request for web.png";
import logoPhiradio from "../assets/logo-apps/2.1 Diba Piradio.png"
import logoAgenda from "../assets/logo-apps/4.1 Diba Agenda.png";
import logoDefault from "../assets/logo-apps/DIBA-default.png";
import logoTracer from "../assets/logo-apps/diba-tracer-study.png";
import logoSapaDisdik from "../assets/logo-apps/diba-komdik.png";
import logoLayananSiswa from "../assets/logo-apps/diba-layanan-siswa.png";
import logoLayananKepeg from "../assets/logo-apps/diba-layanan-kepegawaian.png";
import logoDibaLaper from "../assets/logo-apps/diba-laper.png";
import logoSiterdidik from "../assets/logo-apps/1.1 Diba Superapps.png";
import logoBeasiswa from "../assets/logo-apps/5.1 Diba Beasiswa.png";
import logoPPDB from "../assets/logo-apps/logo-ppdb-2024.png";
import logoDibaSmater from "../assets/logo-apps/diba-smater.png";

const CNavbar = () => {
  const history = useHistory();
  const [openSubmenuId, setOpenSubmenuId] = useState(null);
  const [scrolled, setScrolled] = useState(false);
  const { isMobile, isDesktop } = useDeviceDetect();
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdownId, setActiveDropdownId] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 740) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Untuk efek gulir halus
    });
  };

  // useEffect(() => {
  //     axios.post(`https://us-central1-texttospeech.googleapis.com/v1beta1/text:synthesize&token=03AFcWeA5InL573FfpzYZdGFW4oaNyg5aAp07x3zUPKGCOHxLYmmD0EExzNaTXgFjF1bWE06I08G11jvDXdi2K4Skx0ov1XQRx0WFBCPnXEsz9zrZ_4K3VXi_Dg-dAaCw1fZhFNmbygSqcxPeyarOOvUE7RqbFLlUHzaLEAW6KsWo35OQbuz17FxKGvsruJzYa_vPN8XSq-QXJLUVdtOQ3cW7Kb9uQ0khzyLn2dpy2w7Df4LEw9qCqUpa1ElFeqrZCVY4RMhd5hZBGRllJHzx-7oIdt_DLl2ZRQBw1dj1BcgAzSOWgaYmrc6CcXzyGRhcjJMH2XYz9psrV3aDAOXQX5rdkfgGxFvr0oRhVpk3n5-GnHkk4-CurFvovfz0t_JoHbnlgWACctbwl40wT-WZPYcoAPjt-mdKfepzSiVaGGAEbL898EHfDWma4ygksnNv7TOTpp0IgY4HcVvVqnk8Kpi0PXHf8O-DwOEvzkVE8XJ7CoQP3NnYVMmZ_eOCBC7_jAi8aJ4yWHCmgA1iM0VYuHrUgYc1_bsCKsOfxn-iDF2WZc3Nzn9VDN_g`, {
  //         input: { text: 'Hello, world!' },
  //         voice: { languageCode: 'en-US', ssmlGender: 'NEUTRAL' },
  //         audioConfig: {
  //             "audioEncoding": "LINEAR16",
  //             "pitch": 0,
  //             "speakingRate": 1,
  //             "effectsProfileId": [
  //                 "small-bluetooth-speaker-class-device"
  //             ]
  //         },
  //     })
  //         .then(response => {
  //             // Tangani respons, seperti memainkan suara yang diterima
  //             console.log(response);
  //         })
  //         .catch(error => {
  //             console.error('Gagal mengirim permintaan ke API TTS', error);
  //         });
  // }, []);

  const data = [
    {
      id: 1,
      menu_name: "Profil",
      submenu: [
        {
          id: 1,
          submenu_name: "Profil Pejabat",
          link: "/profile/profil",
          desc: "Menyajikan informasi mengenai profil Kadisdik dan Sekdisdik Provinsi Jawa Barat",
          icon: <FontAwesomeIcon icon={faUserFriends} size="lg" />,
        },
        {
          id: 2,
          submenu_name: "Brand Identity",
          link: "/profile/brand-identity",
          desc: "Menyajikan Informasi mengenai Logo, Maskot, Jingle dan Ringtone",
          icon: <FontAwesomeIcon icon={faBook} size="lg" />,
        },
        {
          id: 3,
          submenu_name: "Sejarah",
          link: "/profile/sejarah",
          desc: "Menyajikan informasi sejarah berdirinya Dinas Pendidikan Provinsi Jawa Barat",
          icon: <FontAwesomeIcon icon={faBook} size="lg" />,
        },

        // {
        //   id: 4,
        //   submenu_name: "Struktur Organisasi",
        //   link: "/profile/struktur",
        //   desc: "Menyajikan informasi struktur organisasi di Dinas Pendidikan Provinsi Jawa Barat",
        //   icon: <FontAwesomeIcon icon={faSitemap} size="lg" />,
        // },
        {
          id: 5,
          submenu_name: "Sekretariat",
          redirect: false,
          link: "/profile/profil-sekretariat",
          desc: "Profil Sekretariat",
          icon: <FontAwesomeIcon icon={faThLarge} size="lg" />,
        },
        {
          id: 6,
          submenu_name: "Bidang GTK",
          redirect: false,
          link: "/profile/profil-gtk",
          desc: "Profil Bidang GTK",
          icon: <FontAwesomeIcon icon={faThLarge} size="lg" />,
        },
        {
          id: 7,
          submenu_name: "Bidang PSMA",
          redirect: false,
          link: "/profile/profil-psma",
          desc: "Profil Bidang PSMA",
          icon: <FontAwesomeIcon icon={faThLarge} size="lg" />,
        },
        {
          id: 8,
          submenu_name: "Bidang PSMK",
          redirect: true,
          link: "https://psmk.jabarprov.go.id/",
          desc: "Profil Bidang PSMK",
          icon: <FontAwesomeIcon icon={faThLarge} size="lg" />,
          // /profile/profil-psmk
        },
        {
          id: 9,
          submenu_name: "Bidang PKLK",
          redirect: false,
          link: "/profile/profil-pklk",
          desc: "Profil Bidang PKLK",
          icon: <FontAwesomeIcon icon={faThLarge} size="lg" />,
        },
        {
          id: 10,
          submenu_name: "Balai Tikomdik",
          redirect: false,
          link: "/profile/profil-tikomdik",
          desc: "Profil Balai Tikomdik",
          icon: <FontAwesomeIcon icon={faThLarge} size="lg" />,
        },

        {
          id: 11,
          submenu_name: "Cabang Dinas",
          redirect: false,
          link: "/profile/profil-kcd",
          desc: "Profil Cabang Dinas",
          icon: <FontAwesomeIcon icon={faThLarge} size="lg" />,
        },
      ],
    },
    // {
    //   id: 2,
    //   menu_name: "Sapadisdik",
    //   submenu: [
    //     {
    //       id: 1,
    //       submenu_name: "Piradio Live Streaming",
    //       redirect: true,
    //       link: "/sapa-disdik/piradio",
    //       desc: "Akses piradio live streaming di Dinas Pendidikan Provinsi Jawa Barat",
    //       icon: <FontAwesomeIcon icon={faHeadphones} size="lg" />,
    //     },
    //     {
    //       id: 2,
    //       submenu_name: "Konten Digital",
    //       redirect: true,
    //       link: "/sapa-disdik/konten-digital",
    //       desc: "Akses media pembelajaran digital di Dinas Pendidikan Provinsi Jawa Barat",
    //       icon: <FontAwesomeIcon icon={faMicrophoneAlt} size="lg" />,
    //     },
    //     {
    //       id: 3,
    //       submenu_name: "Agenda",
    //       redirect: true,
    //       link: "/sapa-disdik/agenda",
    //       desc: "Menyajikan informasi kegiatan-kegiatan di lingkup Dinas Pendidikan Provinsi Jawa Barat",
    //       icon: <FontAwesomeIcon icon={faCalendarWeek} size="lg" />,
    //     },
    //   ],
    // },
    {
      id: 3,
      menu_name: "Layanan Informasi Sapa Disdik",
      submenu: [
        {
          id: 1,
          submenu_name: "DIBA PPDB",
          redirect: true,
          link: "https://ppdb.jabarprov.go.id/",
          desc: "Portal Pendaftaran Peserta Didik Baru Dinas Pendidikan Jawa Barat",
          icon: <img src={logoPPDB} width={35} height={25} />,
        },
        {
          id: 2,
          submenu_name: "DIBA SuperApps",
          redirect: true,
          link: "https://disdik.jabarprov.go.id/siterdidik",
          desc: "Satu Login Untuk Semua Aplikasi Dinas Pendidikan Provinsi Jawa Barat",
          icon: <img src={logoSiterdidik} width={35} height={35} />,
        },
        {
          id: 3,
          submenu_name: "DIBA Beasiswa",
          redirect: true,
          link: "https://beasiswa-jfl.jabarprov.go.id/",
          desc: "Jabar Future Leaders Scholarship",
          icon: <img src={logoBeasiswa} width={35} height={35} />,
        },
        {
          id: 4,
          submenu_name: "DIBA Piradio",
          redirect: true,
          link: "/sapa-disdik/piradio",
          desc: "Radio Streaming Pendidikan",
          icon: <img src={logoPhiradio} width={35} height={35} />,
        },
        {
          id: 5,
          submenu_name: "DIBA KomDik",
          redirect: true,
          link: "/sapa-disdik/konten-digital",
          desc: "Media komunikasi pendidikan Sapa Disdik berisi konten pendidikan video pembelajaran dan audio pembelajaran",
          icon: <img src={logoSapaDisdik} width={45} height={35} />,
        },
        {
          id: 6,
          submenu_name: "DIBA Agenda",
          redirect: true,
          link: "/sapa-disdik/agenda",
          desc: "Menyajikan informasi kegiatan-kegiatan di lingkup Dinas Pendidikan Provinsi Jawa Barat",
          icon: <img src={logoAgenda} width={45} height={35} />,
        },
        {
          id: 7,
          submenu_name: "DIBA Tracer Study SMA",
          redirect: true,
          link: "https://disdik.jabarprov.go.id/survey-lulusan-sma",
          desc: "Mendukung transisi siswa dari sekolah menengah ke dunia kerja atau pendidikan tinggi.",
          icon: <img src={logoTracer} width={45} height={45} />,
        },
        // {
        //   id: 8,
        //   submenu_name: "Layanan Publik Selengkapnya",
        //   redirect: true,
        //   link: "/pages/layanan-publik/LayananSelengkapnya",
        //   desc: "Penerimaan Peserta Didik Baru Provinsi Jawa Barat",
        //   icon: <img src={logoDefault} width={20} height={20} />,
        // },
        {
          id: 8,
          submenu_name: "DIBA Layanan Siswa",
          redirect: true,
          link: "/pages/layanan-publik/diba-layanan-siswa",
          desc: "Menyajikan informasi layanan untuk siswa Dinas Pendidikan Jawa Barat",
          icon: <img src={logoLayananSiswa} width={45} height={45} />,
        },
        {
          id: 9,
          submenu_name: "DIBA Layanan Kepegawaian",
          redirect: true,
          link: "/pages/layanan-publik/diba-layanan-kepegawaian",
          desc: "Menyajikan informasi layanan kepegawaian Dinas Pendidikan Jawa Barat",
          icon: <img src={logoLayananKepeg} width={45} height={40} />,
        },
        {
          id: 10,
          submenu_name: "DIBA LaPer",
          redirect: true,
          link: "https://dibalaper.id/",
          desc: "Akses tools pengelolaan aset barang habis pakai Dinas Pendidikan Provinsi Jawa Barat",
          icon: <img src={logoDibaLaper} width={40} height={30} />,
        },
        {
          id: 11,
          submenu_name: "DIBA SMATER",
          redirect: true,
          link: "https://disdik.jabarprov.go.id/app/smater",
          desc: "Aplikasi Pendaftaran Peserta Didik Baru Untuk SMA Terbuka Tahun 2024 - 2025",
          icon: <img src={logoDibaSmater} width={50} height={40} />,
        },
      ],
    },
    // {
    //   id: 7,
    //   menu_name: 'Layanan Internal',
    //   submenu: [{
    //     id: 1,
    //     submenu_name: "DIBA LaPer",
    //     link: "https://dibalaper.id",
    //     redirect: true,
    //     desc: "Terdidik Terbaik Laporan Persediaan",
    //     icon: <img src={logoDibaLaper} width={35} height={25} />,
    //   },],
    // },
    // {
    //     id: 3,
    //     menu_name: 'Layanan Publik',
    //     submenu: [
    //         { id: 1, submenu_name: 'Layanan Publik', link: '/layanan-publik', desc: 'Menyajikan informasi seputar layanan publik di Dinas Pendidikan Provinsi Jawa Barat', icon: <FontAwesomeIcon icon={faListUl} size="lg" /> },
    //         { id: 2, submenu_name: 'Bidang GTK', redirect: true, link: 'https://disdik.jabarprov.go.id/gtk', desc: 'Website Bidang GTK', icon: <FontAwesomeIcon icon={faThLarge} size="lg" /> },
    //         { id: 3, submenu_name: 'Bidang PSMA', redirect: true, link: 'http://psma.disdik.jabarprov.go.id/', desc: 'Website Bidang PSMA', icon: <FontAwesomeIcon icon={faThLarge} size="lg" /> },
    //         { id: 4, submenu_name: 'Bidang PSMK', redirect: true, link: 'https://psmk.jabarprov.go.id/', desc: 'Website bidang PSMK', icon: <FontAwesomeIcon icon={faThLarge} size="lg" /> },
    //         { id: 5, submenu_name: 'Bidang PKLK', redirect: true, link: 'https://sites.google.com/view/bidang-pklk-disdikjabar/home', desc: 'Website Bidang PKLK', icon: <FontAwesomeIcon icon={faThLarge} size="lg" /> },
    //         { id: 5, submenu_name: 'Balai Tikomdik', redirect: true, link: 'https://tikomdik.jabarprov.go.id/', desc: 'Website Balai Tikomdik', icon: <FontAwesomeIcon icon={faThLarge} size="lg" /> },
    //     ]
    // },
    {
      id: 4,
      menu_name: "Publikasi",
      submenu: [
        {
          id: 1,
          submenu_name: "Berita Artikel",
          link: "/informasi/berita",
          desc: "Menyajikan berita seputar pendidikan di Provinsi Jawa Barat",
          icon: <FontAwesomeIcon icon={faNewspaper} size="lg" />,
        },
        {
          id: 2,
          submenu_name: "Kalendar Pendidikan",
          link: "/pages/layanan-publik/KalendarPendidikan",
          desc: "Menyajikan informasi kalendar pendidikan",
          icon: <FontAwesomeIcon icon={faCalendarAlt} size="lg" />,
        },

      ],
    },
    {
      id: 5,
      menu_name: "PPID",
      submenu: [
        {
          id: 1,
          submenu_name: "Profil PPID",
          link: "/ppid/profil-ppid",
          desc: "Menyajikan daftar informasi publik seputar Dinas Pendidikan Jawa Barat",
          icon: <FontAwesomeIcon icon={faListUl} size="lg" />,
        },
        {
          id: 2,
          submenu_name: "Daftar Informasi Publik",
          link: "/ppid/daftar-informasi",
          desc: "Menyajikan daftar informasi publik seputar Dinas Pendidikan Jawa Barat",
          icon: <FontAwesomeIcon icon={faListUl} size="lg" />,
        },
        // {
        //   id: 2,
        //   submenu_name: "Formulir Pengaduan",
        //   link: "/pengaduan-informasi",
        //   desc: "Formulir pengajuan keberatan terkait permohonan informasi kepada Dinas Pendidikan Provinsi Jawa Barat",
        //   icon: <FontAwesomeIcon icon={faFileAlt} size="lg" />,
        // },
        {
          id: 3,
          submenu_name: "Permohonan Informasi Publik",
          link: "/ppid/informasi-publik",
          desc: "Ajukan permohonan informasi publik kepada Dinas Pendidikan Provinsi Jawa Barat",
          icon: <FontAwesomeIcon icon={faFileAlt} size="lg" />,
        },
        {
          id: 4,
          submenu_name: "Layanan Pengaduan Masyarakat",
          redirect: true,
          link: "/pages/ppid/layanan-ppid",
          desc: "Layanan Pengaduan Masyarakat kepada Dinas Pendidikan Provinsi Jawa Barat",
          icon: <FontAwesomeIcon icon={faFileAlt} size="lg" />,
        },
        {
          id: 5,
          submenu_name: "Layanan Aspirasi dan Pengaduan Online Rakyat",
          redirect: true,
          link: "https://www.lapor.go.id/",
          desc: "Layanan Aspirasi dan Pengaduan Online Rakyat melalui SP4N Lapor!",
          icon: <FontAwesomeIcon icon={faFileAlt} size="lg" />,
        },
        {
          id: 6,
          submenu_name: "SKM",
          redirect: true,
          link: "https://skm.jabarprov.go.id/",
          desc: "Survey Kepuasan Masyarakat",
          icon: <FontAwesomeIcon icon={faFileAlt} size="lg" />,
        },
      ],
    },
    // {
    //   id: 6,
    //   menu_name: 'PPDB',
    //   submenu: [{
    //     id: 1,
    //     submenu_name: "PPDB 2024",
    //     link: "https://ppdb.jabarprov.go.id/",
    //     redirect: true,
    //     desc: "Portal Pendaftaran Peserta Didik Baru Dinas Pendidikan Jawa Barat",
    //     icon: <img src={logoPPDB} width={35} height={25} />,
    //   },],
    // },
  ];

  const openSubmenu = (id) => {
    setOpenSubmenuId(id);
  };

  const closeSubmenu = () => {
    setOpenSubmenuId(null);
  };

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const closeNavbar = () => {
    setIsOpen(false);
  };

  const toggleDropdown = (itemId) => {
    if (activeDropdownId === itemId) {
      setActiveDropdownId(null); // Close the dropdown if it's already open
    } else {
      setActiveDropdownId(itemId); // Open the dropdown
    }
  };

  const linkTo = (route) => {
    closeSubmenu();
    setIsOpen(false);
    history.push(route);
  };

  return (
    <>
      {isMobile ? (
        <nav
          className={`navbar navbar-expand-lg mobile-navbar  ${isOpen ? "open" : ""
            }`}
        >
          <div className="navbar-header-mobile">
            <div className="navbar-header-mobile-logo">
              <div className="logo">
                <a href="/">
                  <img
                    src={logoNegative}
                    alt="Logo Dinas Pendidikan Provinsi Jawa Barat"
                    style={{
                      height: "auto",
                      maxWidth: "5rem",
                    }}
                  />
                </a>
              </div>
            </div>
            <div className="icon">
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleNavbar}
              >
                <FontAwesomeIcon icon={faBars} color="#fff" />
              </button>
            </div>
          </div>

          <div
            className={`collapse navbar-collapse navbar-collapse-mobile ${isOpen ? "show" : ""
              }`}
          >
            <div
              className="navbar navbar-expand-lg mobile-navbar"
              style={{ borderBottom: "1px solid #fff" }}
            >
              <div className="navbar-header-mobile">
                <div className="navbar-header-mobile-logo">
                  <div className="logo">
                    <a href="/">
                      <img
                        src={logoNegative}
                        alt="Logo Dinas Pendidikan Provinsi Jawa Barat"
                        style={{
                          height: "auto",
                          maxWidth: "5rem",
                        }}
                      />
                    </a>
                  </div>
                </div>
                <div className="icon">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleNavbar}
                  >
                    <FontAwesomeIcon icon={faTimes} color="#fff" />
                  </button>
                </div>
              </div>
            </div>

            <ul className="navbar-nav navbar-nav-mobile">
              {data.map((menu, i) => (
                <li key={menu.id} className="nav-item container mb-2">
                  <div
                    className="nav-item-content"
                    onClick={() => toggleDropdown(menu.id)}
                  >
                    <div className="nav-link">{menu.menu_name}</div>
                    <div className="">
                      <FontAwesomeIcon
                        icon={
                          activeDropdownId === menu.id
                            ? faChevronUp
                            : faChevronDown
                        }
                      />
                    </div>
                  </div>
                  {activeDropdownId === menu.id && (
                    <ul className="navbar-content-mobile">
                      {menu.submenu.map((submenu, j) => (
                        <li key={submenu.id}>
                          {submenu?.redirect ? (
                            <a href={submenu.link}>{submenu.submenu_name}</a>
                          ) : (
                            <span onClick={() => linkTo(submenu.link)}>
                              {submenu.submenu_name}
                            </span>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </nav>
      ) : (
        <div
          className={`navbar-custom ${scrolled || openSubmenuId != null ? "scrolled" : ""
            }`}
        >
          <div className="container">
            <div className="navbar-content-custom">
              <div className="logo">
                <a href="/">
                  <img
                    src={logoNegative}
                    alt="Logo Dinas Pendidikan Provinsi Jawa Barat"
                    style={{ width: "8rem" }}
                  />
                </a>
              </div>
              <ul className="navbar-menu-custom">
                {data.map((menu, i) => (
                  <li
                    className="navbar-menu-item-custom"
                    key={menu.id + "-main-" + i}
                  >
                    <div
                      onClick={() => openSubmenu(menu.id)}
                      style={{
                        paddingInline: 10,
                        paddingTop: 5,
                        paddingBottom: 5,
                      }}
                    >
                      <span className="text">{menu.menu_name}</span>
                      <span className="icon">
                        <FontAwesomeIcon icon={faChevronDown} />
                      </span>
                    </div>
                    <div
                      className={`navbar-submenu-custom ${openSubmenuId === menu.id ? "show" : ""
                        }`}
                      onClick={scrollToTop}
                    >
                      <div className="container">
                        <div className="navbar-submenu-header-custom">
                          <div className="navbar-submenu-title">
                            {menu.menu_name}
                          </div>
                          <div
                            className="navbar-submenu-close"
                            onClick={closeSubmenu}
                          >
                            <FontAwesomeIcon icon={faTimes} size="lg" />
                          </div>
                        </div>
                        <ul
                          className={`navbar-submenu-content-custom ${openSubmenuId === menu.id ? "fade-in" : ""
                            }`}
                        >
                          {menu.submenu.map((submenu, j) => (
                            <li key={submenu.id + "-sub-" + j}>
                              {submenu?.redirect ? (
                                <a href={submenu.link}>
                                  <div className="icon-navbar">
                                    <span className="icon">{submenu.icon}</span>
                                  </div>
                                  <div className="text">
                                    <div className="name">
                                      {submenu.submenu_name}
                                    </div>
                                    <div className="desc">{submenu.desc}</div>
                                  </div>
                                </a>
                              ) : (
                                <span onClick={() => linkTo(submenu.link)}>
                                  <div className="icon-navbar">
                                    <span className="icon">{submenu.icon}</span>
                                  </div>
                                  <div className="text">
                                    <div className="name">
                                      {submenu.submenu_name}
                                    </div>
                                    <div className="desc">{submenu.desc}</div>
                                  </div>
                                </span>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CNavbar;
