import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom-v5-compat";

function Banner(props) {
  return (
    <>
      <div style={{ position: "relative" }}>
        <div style={{ height: "280px" }}>
          <div className="banner-custom">
            <div className="breadcrumb-custom">
              <div className="container" style={{ marginTop: 80 }}>
                <div className="breadcrumb-content-custom">
                  {props.list ? (
                    props.list.map((items, index) => {
                      return (
                        <>
                          {items.active ? (
                            <div className="link-home">
                              <Link to={items.link}>{items.title}</Link>
                            </div>
                          ) : (
                            <div className="link">
                              {items.title}&nbsp;{items.second_title ?? ""}
                            </div>
                          )}
                          {index !== props.list.length - 1 && (
                            <div className="icon">
                              <FontAwesomeIcon
                                icon={faChevronRight}
                                size="sm"
                              />
                            </div>
                          )}
                        </>
                      );
                    })
                  ) : (
                    <>
                      <div className="link-home">
                        <a href="/">Beranda</a>
                      </div>
                      <div className="icon">
                        <FontAwesomeIcon icon={faChevronRight} size="sm" />
                      </div>
                      <div className="link">{props.menu}</div>
                    </>
                  )}
                </div>
                <div className="banner-header-custom">
                  <div className="title">
                    {props.menu} {props.second_title}
                  </div>
                  <div className="title"></div>
                  <div className="subtitle">{props.desc}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
