import React, { Component } from "react";
import { MDBFooter, MDBView, MDBRow, MDBCol, MDBContainer } from "mdbreact";
import { BrowserRouter as Router } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";

import { history } from "./helpers/history";
import logoNegative from "./assets/logo-apps/logo disdik special request for web.png";
import logoNegativeLapor from "./assets/Lapor.png";
import Routes from "./Routes";
import moment from "moment-timezone";

import "moment/locale/id";
import Navbar from "./components/Navbar";
import SosialMedia from "./components/SosialMedia";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
moment.tz.setDefault("Asia/Jakarta");
const currentYear = new Date().getFullYear();
class App extends Component {
  state = {
    collapseID: "",
    activeID: "",
    sidebarOpen: false,
    listMenu: [
      {
        main_menu: "Beranda",
        link: "/",
        have_sub_menu: false,
        show_in_sitemap: false,
      },
      {
        main_menu: "Profil",
        sub_menu: [
          {
            sub_menu: "Profil Pejabat",
            link: "/profile/profil",
          },
          {
            sub_menu: "Brand Identity",
            link: "/profile/brand-identity",
          },
          {
            sub_menu: "Sejarah",
            link: "/profile/sejarah",
          },
          {
            sub_menu: "Struktur Organisasi",
            link: "/profile/struktur",
          },
          {
            sub_menu: "Sekretariat",
            link: "/profile/profil-sekretariat",
          },
          {
            sub_menu: "Bidang GTK",
            link: "/halaman/tahap_pengembangan",
          },
          {
            sub_menu: "Bidang PSMA",
            link: "/halaman/tahap_pengembangan",
          },
          {
            sub_menu: "Bidang PSMK",
            link: "/halaman/tahap_pengembangan",
          },
          {
            sub_menu: "Bidang PKLK",
            link: "/halaman/tahap_pengembangan",
          },
          {
            sub_menu: "Balai Tikomdik",
            link: "/profile/profil-tikomdik",
          },
          {
            sub_menu: "Cabang Dinas",
            link: "/profile/profil-kcd",
          },
        ],
        have_sub_menu: true,
        show_in_sitemap: true,
      },
      {
        main_menu: "Layanan Informasi Terpadu",
        sub_menu: [
          {
            sub_menu: "DIBA PPDB",
            link: "https://ppdb.jabarprov.go.id/",
          },
          {
            sub_menu: "DIBA SuperApps",
            link: "https://disdik.jabarprov.go.id/siterdidik",
          },
          {
            sub_menu: "DIBA Beasiswa",
            link: "https://beasiswa-jfl.jabarprov.go.id/",
          },
          {
            sub_menu: "DIBA Piradio",
            link: "/sapa-disdik/piradio",
          },
          {
            sub_menu: "DIBA Sapa Disdik",
            link: "/sapa-disdik/konten-digital",
          },
          {
            sub_menu: "DIBA Agenda",
            link: "/sapa-disdik/agenda",
          },   {
            sub_menu: "DIBA Tracer Study SMA",
            link: "https://disdik.jabarprov.go.id/survey-lulusan-sma",
          },
          {
            sub_menu: "DIBA Layanan Siswa",
            link: "https://disdik.jabarprov.go.id/pages/layanan-publik/diba-layanan-siswa",
          },
          {
            sub_menu: "DIBA Layanan Kepegawaian",
            link: "https://disdik.jabarprov.go.id/pages/layanan-publik/diba-layanan-kepegawaian",
          },
          {
            sub_menu: "DIBA LaPer",
            link: "https://dibalaper.id/login",
          },
        ],
        have_sub_menu: true,
        show_in_sitemap: true,
      },
      {
        main_menu: "Publikasi",
        sub_menu: [
          {
            sub_menu: "Berita Artikel",
            link: "/informasi/berita",
          },
          {
            sub_menu: "Kalender Pendidikan",
            link: "/pages/layanan-publik/KalendarPendidikan",
          },
        ],
        have_sub_menu: false,
        secret: true,
        show_in_sitemap: true,
      },
      // {
      //   main_menu: "Publikasi",
      //   sub_menu: [
      //     {
      //       sub_menu: "Berita Artikel",
      //       link: "/informasi/berita",
      //     },
      //     {
      //       sub_menu: "Kalendar Pendidikan",
      //       link: "/informasi/kalendar_pendidikan",
      //     },
      //   ],
      //   have_sub_menu: true,
      //   show_in_sitemap: true,
      // },
      {
        main_menu: "PPID",
        sub_menu: [
          {
            sub_menu: "Profil PPID",
            link: "/ppid/profil-ppid",
          },
          {
            sub_menu: "Daftar Informasi Publik",
            link: "/ppid/daftar-informasi",
          },
          {
            sub_menu: "Permohonan Informasi Publik",
            link: "/ppid/informasi-publik",
          },
          {
            sub_menu: "Layanan Pengaduan Masyarakat",
            link: "/permohonan-informasi",
          },
          {
            sub_menu: "SP4N Lapor!",
            link: "https://www.lapor.go.id/",
          },
          {
            sub_menu: "SKM (Survey Kepuasan Masyarakat)",
            link: "https://skm.jabarprov.go.id/",
          },
        ],
        have_sub_menu: true,
        show_in_sitemap: true,
      },
    ],
  };

  async componentDidMount() {
    // await axios.get('https://api64.ipify.org?format=json').then(x => {
    //   const data = {
    //     ip_user: x.data.ip,
    //     browser_name: detect().name,
    //     browser_version: detect().version,
    //     user_os: detect().os
    //   }
    //   userService.postData('http://localhost:1998/visited_web', data).then(
    //     user =>{
    //       console.log(user);
    //     }
    //   )
    // })
  }

  toggleCollapse = (collapseID) => () =>
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));

  closeCollapse = (collID) => () => {
    const { collapseID } = this.state;
    collapseID === collID && this.setState({ collapseID: "" });
  };

  ppdbLink = (collID) => () => {
    window.open("https://ppdb.disdik.jabarprov.go.id/", "_blank");
  };

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  componentDidMount() {
    history.listen((location, action) => {
      setTimeout(() => {
        document.getElementById("root").scrollIntoView({ behavior: "smooth" });
      }, 500);
    });
  }

  render() {
    const overlay = (
      <div
        id="sidenav-overlay"
        style={{ backgroundColor: "transparent" }}
        onClick={this.toggleCollapse("mainNavbarCollapse")}
      />
    );

    const { collapseID, sidebarOpen, activeID } = this.state;
    return (
      <Router basename="/" history={history}>
        <CompatRouter>
          <div className="flyout">
            <Navbar />
            {collapseID && overlay}
            <main>
              <SosialMedia />
              <Routes />
            </main>

            {/* </Sidebar> */}
          </div>
        </CompatRouter>
        <MDBFooter className="pt-4 img-fluid">
          <div className="c-bg-footer ">
            <MDBContainer className="text-left">
              <div className="">
                <a href="/">
                  <img
                    src={logoNegative}
                    style={{ height: "auto", width: "200px" }}
                    alt="Logo Dinas Pendidikan Provinsi Jawa Barat"
                  />
                </a>
              </div>
              <MDBRow
                style={{
                  padding: 15,
                }}
              >
                <MDBCol lg="3">
                  <MDBView className="d-flex flex-column">
                    <span className="titleFooter">
                      <span>
                        <i className="fa-solid fa-location-dot"></i>
                      </span>{" "}
                      Alamat
                    </span>
                    <ul className="list-unstyled listContentFooter">
                      <li>
                        <p>
                          Jl. Dr. Rajiman No.6, Pasir Kaliki, Kec. Cicendo, Kota
                          Bandung, Jawa Barat 40171
                        </p>
                      </li>
                    </ul>
                  </MDBView>
                </MDBCol>
                <MDBCol lg="3">
                  <MDBView className="d-flex flex-column">
                    <span className="titleFooter">
                      <span>
                        <i className="fa-solid fa-envelope"></i>
                      </span>{" "}
                      Surel
                    </span>
                    <ul className="list-unstyled">
                      <li>
                        <p>disdik@jabarprov.go.id</p>
                      </li>
                    </ul>
                  </MDBView>
                </MDBCol>
                <MDBCol lg="3">
                  <MDBView className="d-flex flex-column">
                    <span className="titleFooter">
                      <span>
                        <i className="fa-solid fa-handshake-angle"></i>
                      </span>{" "}
                      Bantuan
                    </span>
                    <ul className="list-unstyled">
                      {/* <li>Telp 082127298784</li> */}
                      <li>Telp (022) 4264318 </li>
                      <li>Faks (022) 4264881 </li>
                    </ul>
                  </MDBView>
                </MDBCol>

                <MDBCol lg="3">
                  <MDBView className="d-flex flex-column">
                    <span className="titleFooter">
                      <span>
                        <i className="fa-solid fa-earth-asia"></i>
                      </span>{" "}
                      Media Sosial
                    </span>
                    <div className="c-footer-medsos d-flex justify-content-between align-items-center">
                      <a href="https://www.facebook.com/dinaspendidikanprovjabar">
                        <span>
                          <FontAwesomeIcon icon={faFacebook} />
                        </span>
                      </a>
                      <a href="https://twitter.com/disdik_jabar">
                        <span>
                          <FontAwesomeIcon icon={faXTwitter} />
                        </span>
                      </a>
                      <a href="https://www.instagram.com/disdikjabar/">
                        <span>
                          <FontAwesomeIcon icon={faInstagram} />
                        </span>
                      </a>
                      <a href="https://www.youtube.com/channel/UCWir7ALOPZfyeY9K1-xfqJw">
                        <span>
                          <FontAwesomeIcon icon={faYoutube} />
                        </span>
                      </a>
                    </div>
                    <a href="https://www.lapor.go.id/">
                      <img
                        src={logoNegativeLapor}
                        style={{
                          height: "auto",
                          width: "200px",
                          marginTop: "30px",
                        }}
                        alt="Logo Lapor Go"
                      />
                    </a>
                  </MDBView>
                </MDBCol>
              </MDBRow>

              {/* SITEMAP */}
              <MDBRow
                style={{
                  padding: 15,
                }}
              >
                <MDBCol lg="12">
                  <MDBView className="d-flex flex-column">
                    <span className="titleFooter">
                      <span>
                        <i className="fa-solid fa-network-wired"></i>
                      </span>{" "}
                      Sitemap
                    </span>
                  </MDBView>
                </MDBCol>
                <MDBCol lg="12">
                  <div className="c-wrap-footer-sitemap pt-2">
                    {this.state.listMenu.map((item, index1) => {
                      return item.show_in_sitemap ? (
                        <div key={`sitemap-item-main-${index1}`}>
                          <span>{item.main_menu}</span>
                          {item.have_sub_menu || item.secret ? (
                            <ul className="list-unstyled listContentFooter">
                              {item.sub_menu.map((sub, index2) => {
                                return (
                                  <li key={`sitemap-item-main-${index2}`}>
                                    <a href={sub.link}>{sub.sub_menu}</a>
                                  </li>
                                );
                              })}
                            </ul>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <div
                          style={{ display: "none" }}
                          key={`sitemap-item-main-${index1}`}
                        ></div>
                      );
                    })}
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
          <hr className="hrWhite"></hr>
          <MDBContainer>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                padding: 15,
                width: "100%",
              }}
              className="footerCopy text-center"
            >
              Copyright © {currentYear} Dinas Pendidikan Jawa Barat. All Rights Reserved.
            </div>
          </MDBContainer>
        </MDBFooter>
      </Router>
    );
  }
}

export default App;
