import React, { useEffect, useState, Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faChevronRight,
    faExternalLinkAlt,
    faPencilAlt,
    faSearch,
    faTags,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation, useHistory } from 'react-router-dom';

import { JSDOM } from "jsdom";
import createDOMPurify from "dompurify";
import Banner from "../../components/Banner";
import Api from "../../axios/Api";
import { MDBAnimation, MDBBtn, MDBCard, MDBCardBody, MDBRow } from "mdbreact";
import { faCalendar, faEye } from "@fortawesome/free-regular-svg-icons";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ReactPaginate from "react-paginate";
import { useHelper } from "../../helpers/Helper";
import { Container } from "react-bootstrap";


const window = new JSDOM("").window;
const DOMPurify = createDOMPurify(window);

const SapaDisdik = (props) => {
    const location = useLocation();
    const history = useHistory();
    const params = new URLSearchParams(location.search);
    const [berita, setBerita] = useState([]);
    const [querySearch, setQuerySearch] = useState("");
    const [valueSearch, setValueSearch] = useState("");
    const [refresh, setRefresh] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const banyakPerHalaman = 3;

    useEffect(() => {
        //Get Query Search
        const q = params.get('query');
        setQuerySearch(q);
        setValueSearch(q);
    }, [location]);

    useEffect(() => {
        const q = params.get('query');
        setRefresh(true);
        const getDataBerita = async () => {
            await Api.get(`/news?page=1&size=3&search=${q}`).then((res) => {
                setPageCount(Math.ceil(res.data.jumlahData / banyakPerHalaman));
                setBerita(res.data.data);
                setTimeout(() => {
                    setRefresh(false);
                }, 2000);
            })
        }

        getDataBerita();
    }, [location, props])

    const handlePageClick = async (e) => {
        const selectedPage = e.selected + 1;
        setCurrentPage(selectedPage);
        if (querySearch === "") return;
        const api = "/news?page=" + selectedPage + "&size=" + banyakPerHalaman + "&search=" + querySearch;

        setRefresh(true);

        setTimeout(() => {
            document.getElementById("root").scrollIntoView({ behavior: "smooth" });
        }, 500);
        await Api.get(api).then((res) => {
            setPageCount(Math.ceil(res.data.jumlahData / banyakPerHalaman));
            setBerita(res.data.data);
            setTimeout(() => {
                setRefresh(false);
            }, 2000);
        })
    };

    const handleSearch = (e) => {
        e.preventDefault();
        const q = e.target.query_search.value;
        history.push(`/pencarian?query=${q}`);
    }

    return (
        <>
            <Banner
                menu={'Pencarian'}
                desc={'Temukan informasi publik terkini dari Dinas Pendidikan Jawa Barat'}
            />

            <section className="section-pencarian-berita">
                <Container>
                    <form onSubmit={handleSearch}>
                        {/* <div className="container input-group d-flex flex-row align-items-center">
                        <input value={valueSearch} onChange={(e) => setValueSearch(e.target.value)} name="query_search" type="text" className="form-control input-custom" placeholder="Cari..." autoComplete="off" />
                        <div className="">
                            <button type="submit" className="btn btn-search-home-custom"><FontAwesomeIcon icon={faSearch} /></button>
                        </div>
                    </div> */}
                        <div className="input-with-button d-flex align-items-center">
                            <div className="input-container" style={{ flex: '1', }}>
                                <input
                                    className="form-control input-custom"
                                    type="text"
                                    name="query_search"
                                    placeholder="Cari informasi"
                                    autoComplete={'off'}
                                    onChange={(e) => setValueSearch(e.target.value)}
                                    value={valueSearch}
                                />
                            </div>
                            <button type="submit" className="btn btn-search-home-custom"><FontAwesomeIcon icon={faSearch} /></button>
                        </div>
                    </form>
                </Container>
                <div className="container">
                    <h5 className="mt-4 mb-4">Menampilkan hasil pencarian <b style={{ color: '#0D47A1' }}>{querySearch}</b></h5>

                    <div className="mt-5 ">
                        <div className="d-flex justify-content-start align-items-start padding-null">
                            <h5>
                                Berita Dinas Pendidikan Provinsi Jawa Barat terkait <b style={{ color: '#0D47A1' }}>{querySearch}</b>
                            </h5>
                        </div>
                    </div>
                    <div className="about-box">
                        <MDBRow className="d-flex justify-content-center pt-4">
                            {!refresh
                                ?
                                <>{berita.length > 0 ?
                                    berita.map((x, index) => (
                                        <div className="col-lg-4 col-md-12 mb-4" key={index}>
                                            <MDBAnimation type="fadeIn" key={refresh}>
                                                <MDBCard className="card-events-countdown">
                                                    <div className="padding-null">
                                                        <div
                                                            className="events-separator d-flex justify-content-end flex-column"
                                                            style={{
                                                                backgroundImage: "url(" + x.url_thumb_news + ")",
                                                            }}
                                                        ></div>
                                                    </div>
                                                    <MDBCardBody className="d-flex flex-column justify-content-between">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="events-date dark-grey-text">
                                                                <FontAwesomeIcon icon={faCalendar} /> &nbsp;{" "}
                                                                {x.tanggal_dibuat}, {x.detail_time}
                                                                <p className="events-date-1 dark-grey-text mt-2">
                                                                    <FontAwesomeIcon icon={faTags} /> &nbsp; {x.uploader}
                                                                </p>
                                                            </div>

                                                            <div className="events-view dark-grey-text">
                                                                <FontAwesomeIcon icon={faEye} /> &nbsp;
                                                                {x.viewed} views
                                                                <p className="events-view dark-grey-text mt-2">
                                                                    <FontAwesomeIcon icon={faPencilAlt} /> &nbsp;
                                                                    {x.penulis}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div style={{ minHeight: 120, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                            <div className="news-textTitle">
                                                                <p>
                                                                    {useHelper.highlightSearch({ search: querySearch, content: x.title })}
                                                                </p>
                                                            </div>
                                                            <div style={{ height: 1, backgroundColor: 'black', marginBottom: 20 }}></div>
                                                        </div>
                                                        <a
                                                            className="d-flex justify-content-center align-items-center"
                                                            href={"/berita/" + x.sort_url}
                                                        >
                                                            <MDBBtn outline color="primary">
                                                                Terus Membaca
                                                            </MDBBtn>
                                                        </a>
                                                        <div className="d-flex justify-content-center"></div>
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </MDBAnimation>
                                        </div>
                                    ))
                                    :
                                    <>
                                        <div className="col-sm-12 col-lg-12 d-flex justify-content-center p-4">
                                            <MDBAnimation type="fadeIn">
                                                <div className="d-flex justify-content-center">
                                                    <h5 className="text-center">Data tidak ditemukan</h5>
                                                </div>
                                            </MDBAnimation>
                                        </div>
                                    </>
                                }
                                </> : [0, 1, 2].map((_x, key) => {
                                    return (
                                        <div className="col-sm-12 col-lg-4 d-flex justify-content-center p-4" key={key}>
                                            <MDBAnimation type="fadeIn" key={refresh}>
                                                <SkeletonTheme
                                                    baseColor="#e1e1e1"
                                                    highlightColor="#fffefe"
                                                    className="m-2"
                                                    width={300}
                                                    height={400}
                                                >
                                                    <Skeleton count={1} style={{
                                                        width: '100%',
                                                        height: 100,
                                                        borderRadius: 10
                                                    }} />
                                                </SkeletonTheme>
                                            </MDBAnimation>
                                        </div>
                                    );
                                })}

                        </MDBRow>
                    </div>

                    {
                        berita.length > 0 ?
                            <ReactPaginate
                                previousLabel={<FontAwesomeIcon icon={faChevronLeft} size="sm" />}
                                nextLabel={<FontAwesomeIcon icon={faChevronRight} size="sm" />}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={pageCount}
                                onPageChange={(e) => handlePageClick(e)}
                                initialPage={currentPage}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={2}
                                containerClassName={"pagination-news"}
                                previousClassName="previous-pag"
                                nextClassName="next-pag"
                                pageClassName="page-pag"
                                subContainerClassName={"pagination-sub"}
                                activeClassName={"active"}
                            />
                        : <></>
                    }
                </div>
            </section>
        </>
    );
};

export default SapaDisdik;
