import React, { Component } from "react";
import { Link as LinkTo } from "react-router-dom";
import {
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCarousel,
  MDBCarouselCaption,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBView,
  MDBMask,
  MDBCardBody,
  MDBContainer,
  MDBTypography,
  MDBCard,
  MDBCardTitle,
  MDBBtn,
  MDBCollapse,
} from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faEye,
  faSearch,
  faTags,
  faPencilAlt,
  faComments,
  faUser,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";
import { JSDOM } from "jsdom";
import createDOMPurify from "dompurify";
import Swal from "sweetalert2";
import API from "../../../src/axios/Api";
import { Badge, Button, Form } from "react-bootstrap";
import { useHelper } from "../../helpers/Helper";
import logoDisdik from "../../assets/disdikjabar.png";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import Banner from "../../components/Banner";

const window = new JSDOM("").window;
const DOMPurify = createDOMPurify(window);
class NewsDetail extends Component {
  state = {
    activeItem: "1",
    pageCuk: 0,
    activeItemCaution: "1",
    detailnews: [],
    detailimage: [],
    detaillistnews: [],

    listKomentar: [],
    formBalasID: "",
    banyakKomentar: 0,
  };

  componentDidMount = async () => {
    if (this.props.match.params.id) {
      const V = localStorage.getItem("newsId");
      await API.get("/news/detail/dev?param=" + this.props.match.params.id)
        .then((response) => {
          if (response.data.data != undefined) {
            this.setState({
              detailnews: response.data.data,
              detailimage: response.data.dataImage,
            });
          } else {
            this.props.history.push("/404");
          }
        })
        .catch((err) => {
          this.props.history.push("/404");
        });
      await API.get("/news?limit=2&offset=0")
        .then((response) => {
          this.setState({
            detaillistnews: response.data.data,
          });
        })
        .catch((err) => {
          this.props.history.push("/404");
        });
      await API.get("/coments?id_news=" + this.state.detailnews.id_news)
        .then((response) => {
          this.setState({
            listKomentar: response.data.data,
            banyakKomentar: response.data.jumlahData,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      this.props.history.push("/404/");
    }
  };

  getKomentar = async () => {
    if (this.props.match.params.id) {
      await API.get("/coments?id_news=" + this.state.detailnews.id_news)
        .then((response) => {
          this.setState({
            listKomentar: response.data.data,
            banyakKomentar: response.data.jumlahData,
          });
        })
        .catch((err) => {
          this.props.history.push("/404");
        });
    }
  };

  linkToNews(route, newsId, id) {
    localStorage.setItem("newsId", id);
    this.props.history.push(route);
    this.linkToCoba();
  }

  toggleCollapse = (collapseID) => () => {
    this.setState((prevState) => ({
      formBalasID: prevState.formBalasID !== collapseID ? collapseID : "",
    }));
  };

  linkToCoba = async () => {
    const V = localStorage.getItem("newsId");
    await API.get("/news/detail/dev?param=" + V)
      .then((response) => {
        if (response.data.data != undefined) {
          this.setState({
            detailnews: response.data.data,
            detailimage: response.data.dataImage,
          });
        } else {
          this.props.history.push("/404");
        }
      })
      .catch((err) => {
        this.props.history.push("/404");
      });
  };

  handleSubmitMain = async (e) => {
    e.preventDefault();
    var data = {
      nama: e.target.nama.value,
      email: e.target.email.value,
      isi_komentar: e.target.kirim_komentar.value,
      id_news: this.state.detailnews.id_news,
    };
    await API.post("/coments?balas=false", data)
      .then((response) => {
        Swal.fire({
          title: "<strong>Terimakasih</strong>",
          icon: "info",
          html:
            "Terimakasih telah memberi komentar<br/>" +
            "Komentar anda akan kami terima terlebih dahulu, sebelum terpublish",
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: '<i className="fa fa-thumbs-up"></i> Siap!',
          confirmButtonAriaLabel: "Sip, min!",
          cancelButtonText: '<i className="fa fa-thumbs-down"></i>',
          cancelButtonAriaLabel: "Thumbs down",
        }).then((x) => {
          //this.moveTo('top');
          global.location.reload();
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Sesuatu ada yang salah!",
          //footer: '<a href="">Why do I have this issue?</a>'
        });
      });
  };

  handleSubmitBalas = async (e) => {
    e.preventDefault();
    var data = {
      nama: e.target.nama.value,
      email: e.target.email.value,
      isi_komentar: e.target.kirim_komentar.value,
      id_komentar: e.target.id_komentar.value,
      id_news: this.state.detailnews.id_news,
    };
    await API.post("/coments?balas=true", data)
      .then((response) => {
        Swal.fire({
          title: "<strong>Terimakasih</strong>",
          icon: "info",
          html:
            "Terimakasih telah memberi komentar balasan<br/>" +
            "Komentar anda akan kami terima terlebih dahulu, sebelum terpublish",
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: '<i className="fa fa-thumbs-up"></i> Siap!',
          confirmButtonAriaLabel: "Sip, min!",
          cancelButtonText: '<i className="fa fa-thumbs-down"></i>',
          cancelButtonAriaLabel: "Thumbs down",
        }).then((x) => {
          //this.moveTo('top');
          global.location.reload();
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Sesuatu ada yang salah!",
          //footer: '<a href="">Why do I have this issue?</a>'
        });
      });
  };

  linkTo(route) {
    this.props.history.push(route);
  }

  moveTo = (id) => {
    setTimeout(() => {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    }, 10);
  };

  render() {
    const { banyakKomentar, listKomentar } = this.state;
    return (
      <>
        <Banner
          menu={"Berita Dinas Pendidikan Jawa Barat"}
          desc={this.state.detailnews.title}
        />
        <MDBContainer fluid className="justify-content-md-center">
          <MDBRow
            className="justify-content-md-center"
            style={{ marginTop: "5vh" }}
            id="top"
          >
            <MDBCol lg="5">
              <MDBTypography
                className="text-center font-weight-bold "
                tag="h5"
                style={{ color: "#3385ff", fontSize: "30px" }}
              >
                {this.state.detailnews.title}
              </MDBTypography>
              <div className="subhead-news">
                <div
                  className="badge-comments"
                  onClick={() => this.moveTo("comment-section")}
                >
                  <div>
                    <FontAwesomeIcon icon={faComments} />
                  </div>
                  <div className="pl-1 pr-1"></div>
                  <div>{banyakKomentar} Komentar</div>
                </div>
                <div className="d-flex flex-row">
                  <div className="p-2">
                    <MDBIcon
                      size="2x"
                      className="red-text"
                      fab
                      icon="instagram"
                    />
                  </div>
                  <div className="p-2">
                    {" "}
                    <MDBIcon
                      size="2x"
                      className="blue-text"
                      fab
                      icon="facebook"
                    />
                  </div>
                  <div className="p-2">
                    {" "}
                    <MDBIcon
                      size="2x"
                      className="cyan-text"
                      fab
                      icon="twitter"
                    />
                  </div>
                </div>
              </div>

              <MDBCarousel
                activeItem={1}
                length={this.state.detailimage.length}
                showControls={true}
                showIndicators={true}
                className="z-depth-1"
              >
                <MDBCarouselInner>
                  {this.state.detailimage.length > 0 ? (
                    this.state.detailimage.map((x, index) => {
                      return (
                        <MDBCarouselItem itemId={index + 1} key={index}>
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={x.path}
                              alt={x.filename}
                            />
                            <MDBMask overlay="black-light" />
                          </MDBView>
                        </MDBCarouselItem>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </MDBCarouselInner>
              </MDBCarousel>
              <MDBTypography className="news-textTitle1">
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(this.state.detailnews.caption),
                  }}
                />
              </MDBTypography>

              <div
                className="row subtitle-detail-content"
                style={{ marginTop: "20px" }}
              >
                <div className="col-lg-auto col-md-auto col-sm-6">
                  <span>
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </span>{" "}
                  &nbsp;Oleh {this.state.detailnews.penulis}
                </div>

                <div className="col-lg-auto col-md-auto col-sm-6">
                  <span>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </span>{" "}
                  &nbsp;{this.state.detailnews.tanggal_dibuat},{" "}
                  {this.state.detailnews.detail_time}
                </div>
                <div className="col-lg-auto col-md-auto col-sm-6">
                  <span>
                    <FontAwesomeIcon icon={faEye} />
                  </span>{" "}
                  &nbsp;{this.state.detailnews.viewed} Views
                </div>
                <div className="col-lg-auto col-md-auto col-sm-6">
                  <span>
                    <FontAwesomeIcon icon={faTags} />
                  </span>{" "}
                  &nbsp;{this.state.detailnews.uploader}
                </div>
                {/* <div className="col-lg-auto col-md-auto col-sm-6">
                <span><FontAwesomeIcon icon={faTags} flip="horizontal" /></span> &nbsp;Education, Technology
              </div> */}
              </div>
              <br />

              <MDBTypography
                style={{
                  letterSpacing: "2px",
                  textAlign: "justify",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(this.state.detailnews.deskripsi),
                  }}
                />
              </MDBTypography>
              <div id="comment-section" className="comment-section">
                <hr />
                <div className="comment-head">Komentar</div>
                <Form onSubmit={this.handleSubmitMain}>
                  <div className="comment-form">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Nama</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Nama Anda"
                        name="nama"
                        required
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="name@example.com"
                        name="email"
                        required
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Komentar Anda</Form.Label>
                      <Form.Control
                        name="kirim_komentar"
                        as="textarea"
                        rows={3}
                        placeholder="Tuliskan Komentar Anda"
                        required
                      />
                    </Form.Group>
                  </div>
                  <div>
                    <Button variant="primary" size="sm" type="submit">
                      Kirim
                    </Button>
                  </div>
                </Form>
                <br />
                {listKomentar.length > 0 ? (
                  listKomentar.map((x, key) => {
                    return (
                      <div
                        key={key}
                        id="true-comments"
                        className="true-comments"
                      >
                        <div className="head">
                          <div>
                            <div
                              className="image-static"
                              style={{
                                backgroundColor: useHelper.get_random_color(),
                              }}
                            >
                              {useHelper.getInitials(x.nama)}
                            </div>
                          </div>
                          <div className="ml-2">
                            <div className="head-title">
                              {x.nama}&nbsp;
                              {x.is_verified == 1 ? (
                                <FontAwesomeIcon icon={faCheck} size="sm" />
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="head-subtitle">
                              {moment
                                .utc(x.create_date, "YYYY-MM-DD HH:mm:ss")
                                .tz("Asia/Jakarta")
                                .fromNow()}
                            </div>
                          </div>
                        </div>
                        <div className="comment">{x.isi_komentar}</div>
                        <br />
                        <div
                          className="footer"
                          onClick={this.toggleCollapse("basicCollapse" + key)}
                        >
                          Balas
                        </div>
                        <MDBCollapse
                          id={"basicCollapse" + key}
                          isOpen={this.state.formBalasID}
                        >
                          <Form
                            onSubmit={this.handleSubmitBalas}
                            style={{ marginRight: 20 }}
                          >
                            <div className="comment-form">
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  type="hidden"
                                  value={x.id_komentar}
                                  name="id_komentar"
                                  hidden
                                />
                              </Form.Group>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>Nama</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Nama Anda"
                                  name="nama"
                                  required
                                />
                              </Form.Group>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput2"
                              >
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                  type="email"
                                  placeholder="name@example.com"
                                  name="email"
                                  required
                                />
                              </Form.Group>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlTextarea1"
                              >
                                <Form.Label>Komentar Anda</Form.Label>
                                <Form.Control
                                  name="kirim_komentar"
                                  as="textarea"
                                  rows={3}
                                  placeholder="Tuliskan Komentar Anda"
                                  required
                                />
                              </Form.Group>
                            </div>
                            <div>
                              <Button variant="primary" size="sm" type="submit">
                                Kirim Balasan
                              </Button>
                            </div>
                          </Form>
                          <br />
                        </MDBCollapse>
                        {x.sub_coments ? (
                          x.sub_coments.length > 0 ? (
                            x.sub_coments.map((z, key) => {
                              return (
                                <div key={key} style={{ marginLeft: 60 }}>
                                  <div className="head">
                                    <div>
                                      {z.is_admin == 1 ? (
                                        <img
                                          src={logoDisdik}
                                          width={50}
                                          alt="admin"
                                        ></img>
                                      ) : (
                                        <div
                                          className="image-static"
                                          style={{
                                            backgroundColor:
                                              useHelper.get_random_color(),
                                          }}
                                        >
                                          {useHelper.getInitials(z.nama)}
                                        </div>
                                      )}
                                    </div>
                                    <div className="ml-2">
                                      <div className="head-title">
                                        {z.nama}&nbsp;
                                        {z.is_admin == 1 ||
                                        z.is_verified == 1 ? (
                                          <FontAwesomeIcon
                                            icon={faCheck}
                                            size="sm"
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="head-subtitle">
                                        {moment(
                                          z.create_date,
                                          "YYYY-MM-DD HH:mm:ss"
                                        ).fromNow()}
                                      </div>
                                    </div>
                                    {/* <Overlay */}
                                  </div>
                                  <div className="comment">
                                    {z.isi_komentar}
                                  </div>
                                  <br />
                                  <div
                                    className="footer"
                                    onClick={this.toggleCollapse(
                                      "basicCollapseSub" + key
                                    )}
                                  >
                                    Balas
                                  </div>
                                  <MDBCollapse
                                    id={"basicCollapseSub" + key}
                                    isOpen={this.state.formBalasID}
                                  >
                                    <Form
                                      onSubmit={this.handleSubmitBalas}
                                      style={{ marginRight: 20 }}
                                    >
                                      <div className="comment-form">
                                        <Form.Group
                                          className="mb-3"
                                          controlId="exampleForm.ControlInput1"
                                        >
                                          <Form.Control
                                            type="hidden"
                                            value={x.id_komentar}
                                            name="id_komentar"
                                            hidden
                                          />
                                        </Form.Group>
                                        <Form.Group
                                          className="mb-3"
                                          controlId="exampleForm.ControlInput1"
                                        >
                                          <Form.Label>Nama</Form.Label>
                                          <Form.Control
                                            type="text"
                                            placeholder="Nama Anda"
                                            name="nama"
                                            required
                                          />
                                        </Form.Group>
                                        <Form.Group
                                          className="mb-3"
                                          controlId="exampleForm.ControlInput2"
                                        >
                                          <Form.Label>Email</Form.Label>
                                          <Form.Control
                                            type="email"
                                            placeholder="name@example.com"
                                            name="email"
                                            required
                                          />
                                        </Form.Group>
                                        <Form.Group
                                          className="mb-3"
                                          controlId="exampleForm.ControlTextarea1"
                                        >
                                          <Form.Label>Komentar Anda</Form.Label>
                                          <Form.Control
                                            name="kirim_komentar"
                                            as="textarea"
                                            rows={3}
                                            placeholder="Tuliskan Komentar Anda"
                                            required
                                          />
                                        </Form.Group>
                                      </div>
                                      <div>
                                        <Button
                                          variant="primary"
                                          size="sm"
                                          type="submit"
                                        >
                                          Kirim Balasan
                                        </Button>
                                      </div>
                                    </Form>
                                    <br />
                                  </MDBCollapse>
                                </div>
                              );
                            })
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <>
                    <div id="no-comments" className="no-comments">
                      <b>Belum ada komentar.</b>
                      <br />
                      Jadilah yang pertama berkomentar di sini
                    </div>
                    <br />
                  </>
                )}
              </div>
            </MDBCol>
            <MDBCol
              className="follow-scroll"
              lg="3"
              style={{ marginBottom: "20px" }}
            >
              <MDBCard>
                <MDBCardBody>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Pencarian Berita"
                      aria-label="Username"
                      aria-describedby="basic-addon"
                    />
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon">
                        <FontAwesomeIcon icon={faSearch} />
                      </span>
                    </div>
                  </div>

                  <MDBCardTitle style={{ color: "black" }}>
                    Berita Terbaru
                  </MDBCardTitle>
                  <hr />
                  {this.state.detaillistnews.length > 0 ? (
                    this.state.detaillistnews.map((x, index) => {
                      return (
                        <div key={index}>
                          <div>
                            <img
                              className="mb-3"
                              src={x.url_thumb_news}
                              alt={x.title}
                              style={{ width: "100%", minHeight: "15vh" }}
                            />
                            <div className="news-title">{x.title}</div>
                            <div className="news-content" style={{ fontSize: 14 }}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    DOMPurify.sanitize(x.deskripsi).substring(
                                      0,
                                      150
                                    ) + ". . . ",
                                }}
                              />
                              <a
                                style={{ color: "#d62d20" }}
                                href={
                                  "/berita/" +
                                  x.sort_url
                                }
                              >
                                Baca Selengkapnya...
                              </a>
                              {/* <LinkTo
                                onClick={() =>
                                  this.linkToNews(
                                    "/informasi/berita/education/detail/" + x.sort_url,
                                    x.id_news, x.sort_url
                                  )
                                }
                                style={{ color: "#d62d20" }}
                              >
                                Baca Selengkapnya...
                              </LinkTo> */}
                            </div>
                          </div>
                          <hr />
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}

                  <div>
                    <div className="news-all">
                      <LinkTo
                        to={"/berita"}
                        style={{ color: "#0057e7" }}
                      >
                        Lihat Semua Berita <MDBIcon icon="angle-double-right" />
                      </LinkTo>
                    </div>
                  </div>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </>
    );
  }
}

export default NewsDetail;
