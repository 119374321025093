import React, { useRef, useState, useEffect } from "react";
import {
  faFacebook,
  faInstagram,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function SosialMedia() {
  const iconRef = useRef(null);

  // useEffect(() => {
  //   const handleIntersection = (entries) => {
  //     entries.forEach(entry => {
  //       if (entry.isIntersecting) {
  //         // Elemen tumpang tindih dengan latar belakang putih
  //         entry.target.style.color = 'black';
  //         console.log('Element has just become visible in screen');
  //       } else {
  //         // Elemen tidak tumpang tindih dengan latar belakang putih
  //         entry.target.style.color = 'white';
  //         console.log('Element has just become invisible in screen');
  //       }
  //     });
  //   };

  //   const observer = new IntersectionObserver(handleIntersection, {
  //     threshold: 0.5
  //   });

  //   if (iconRef.current) {
  //     observer.observe(iconRef.current);
  //   }

  //   return () => {
  //     if (iconRef.current) {
  //       observer.unobserve(iconRef.current);
  //     }
  //   };
  // }, []);
  return (
    <div className="icon-bar" ref={iconRef}>
      <div className="c-list-sosmed d-flex justify-content-between align-items-center flex-column">
        <a href="https://www.facebook.com/dinaspendidikanprovjabar">
          <span>
            <FontAwesomeIcon icon={faFacebook} />
          </span>
        </a>
        <a href="https://twitter.com/disdik_jabar">
          <span>
            <FontAwesomeIcon icon={faXTwitter} />
          </span>
        </a>
        <a href="https://www.instagram.com/disdikjabar/">
          <span>
            <FontAwesomeIcon icon={faInstagram} />
          </span>
        </a>
        <a href="https://www.youtube.com/channel/UCWir7ALOPZfyeY9K1-xfqJw">
          <span>
            <FontAwesomeIcon icon={faYoutube} />
          </span>
        </a>
      </div>
    </div>
  );
}

export default SosialMedia;
