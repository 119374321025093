import React, { useEffect, useState, Component } from "react";
import moment from 'moment';

export const useHelper = {
    getInitials,
    get_random_color,
    longText,
    formatRupiah,
    getIdYoutube,
    highlightSearch,
    diffDateTime
};

function getInitials(fullName) {
    const allNames = fullName.trim().split(' ');
    const initials = allNames.reduce((acc, curr, index) => {
        if (index === 0 || index === allNames.length - 1) {
            acc = `${acc}${curr.charAt(0).toUpperCase()}`;
        }
        return acc;
    }, '');
    return initials;
}

function get_random_color() {
    function c() {
        var hex = Math.floor(Math.random() * 256).toString(16);
        return ("0" + String(hex)).substr(-2); // pad with zero
    }
    return "#" + c() + c() + c();
}

function longText(text) {
    return text.length > 200 ? text.substring(0, 200) + "..." : text;
}

/* Fungsi formatRupiah */
function formatRupiah(angka, prefix) {
    var number_string = angka.replace(/[^,\d]/g, '').toString(),
        split = number_string.split(','),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    // tambahkan titik jika yang di input sudah menjadi angka ribuan
    if (ribuan) {
        var separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
    return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
}

function getIdYoutube(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
        ? match[2]
        : null;
}

function highlightSearch({ search, content }) {
    const parts = content.split(new RegExp(`(${search})`, 'gi'));

    return (
        <span>
            {parts.map((part, i) =>
                part.toLowerCase() === search.toLowerCase() ?
                    <strong key={i}>{part}</strong> :
                    part
            )}
        </span>
    );
}

function diffDateTime(createDate) {
    const now = moment();
    const created = moment(createDate);
    const diffInDays = now.diff(created, 'days');
    const diffInHours = now.diff(created, 'hours');

    if (diffInDays >= 1) {
        return `${diffInDays} hari lalu`;
    } else if (diffInHours >= 1) {
        return `${diffInHours} jam lalu`;
    } else {
        return `Baru saat ini`;
    }
};